import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN WYOMING' activeCard='wyoming' image={'/Wyoming_BG.jpeg'}>
          <p>
            Apollo has served clients all over the Midwest, Southeast and Southwest regions of the United States since 2010. Now there is affordable insurance
            in Wyoming. Our mission is to serve our clients by meeting their needs, assisting them to find the best healthcare package which also fits within
            their budget. We customize their policy, creating a tailored solution to their healthcare needs. We know the struggles many people face with health
            insurance and how frustrating it can be. Our team knew we had to do the best job we could, not only learning about the industry, but mastering the
            intricacies of every plan on the market and know how to structure them differently depending on our clients individual needs.{' '}
            <p>
              We begin every client journey by discovering health insurance needs and work tirelessly to find affordable options that fit those needs. Apollo
              Insurance advisors help Wisconsin consumers with their health insurance, financial planning, life insurance, and Medicare solutions. We also work
              with small businesses to locate reliable group insurance plans.
            </p>
            <p>
              At Apollo Insurance we provide affordable solutions for our clients seeking insurance in Wyoming. As consumers ourselves, we know the stress of
              witnessing rates increase on premiums this year. We listen to your needs and make sure you receive affordable insurance in Wyoming.
            </p>
          </p>
          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket
          </p>

          <p>Contact us today to secure quality and affordable healthcare in the state of Wyoming.</p>
        </Content>
      </div>
    </Layout>
  );
};
